<template>
  <Swiper
    v-if="channels.length"
    :navigation="true"
    :modules="[Navigation]"
    :breakpoints="commonBreakPoints"
    :slides-per-view="channelSlidesPerView"
    :space-between="spaceBetween"
    :initial-slide="getSlideKey"
    class="channel_swiper"
    @slide-change="setSlideKey"
  >
    <SwiperSlide
      v-for="channel in channels"
      :key="channel.id || channel.channel_id"
      class="channel_slider"
    >
      <ChannelCard
        :channel="channel"
        :show-epg="false"
      />
    </SwiperSlide>
  </Swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import ChannelCard from '@/components/ui/Channels/ChannelCard.vue';
import { commonBreakPoints } from '@/components/ui/breakPoints';

const props = defineProps({
  channels: {
    type: Array,
    default: () => [],
  },
  localStorageKey: {
    type: String,
    default: '',
  },
});

// slider
const { channelSlidesPerView, spaceBetween } = storeToRefs(usePosterDetailsStore());
const slideKey = useLocalStorage('sliderKey', { [props.localStorageKey]: 0 });
const setSlideKey = ({ activeIndex }) => slideKey.value = { [props.localStorageKey]: activeIndex };
// const getSlideKey = useLocalStorage([props.localStorageKey], 0);
// const getSlideKey = useLocalStorage('sliderKey', 0);
const getSlideKey = computed(() => slideKey.value[props.localStorageKey]);
// console.log('getSlideKey:', getSlideKey.value);
</script>
